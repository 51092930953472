import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import { AppContext } from "~context/AppContext.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import * as Logo from "~components/svg/Logos.jsx";
import * as T from "~components/styles/Typography.jsx";
import Go from "~components/Go.jsx";

const Header = () => {
  const { menuActive, setMenuActive, setResetSearch } = useContext(AppContext);
  const { isDesktop } = useContext(DocumentContext);

  //

  if (!isDesktop()) {
    return (
      <header
        css={[
          css`
            //
          `,
          tw`w-full fixed top-0 right-0 left-0 z-40 bg-white border-t border-b border-koamaru text-koamaru`
        ]}
      >
        <Grid node="nav" styles={[tw`relative items-center`]}>
          <div
            css={[
              css`
                //
                //height: 64px;
                padding: 0 8.3333vw;
              `,
              tw`col-span-24 h-16 relative flex items-center`
            ]}
          >
            <button
              type="button"
              css={[
                css`
                  width: 15%;
                `,
                tw`relative block`
              ]}
              onClick={() => {
                setMenuActive(!menuActive);
              }}
            >
              <Icon.Burger
                styles={[
                  css`
                    width: 24px;
                  `,
                  tw`relative block`
                ]}
              />
            </button>

            <Go
              to="https://www.frankdarling.com"
              styles={[
                css`
                  // padding: 14px 0 14px 14px;
                  width: 60%;
                  display: block;
                `
              ]}
            >
              <Logo.Wordmark
                styles={[
                  css`
                    //height: 19px;
                  `,
                  tw`w-full relative`
                ]}
              />
            </Go>

            <div
              css={[
                css`
                  width: 30%;
                `,
                tw`flex`
              ]}
            >
              <div css={[tw`w-full relative flex items-center justify-end`]}>
                <Go to="https://www.frankdarling.com/wishlist">
                  <Icon.Wishlist
                    styles={[
                      css`
                        width: 24px;
                      `,
                      tw`relative block`
                    ]}
                  />
                </Go>

                <Go to="https://www.frankdarling.com">
                  <Icon.Cart
                    styles={[
                      css`
                        width: 24px;
                        margin: 0 0 0 4.5vw;
                      `,
                      tw`relative block`
                    ]}
                  />
                </Go>
              </div>
            </div>

            {/* <div
              css={[
                css`
                  width: 24px;
                `,
                tw`border`
              ]}
            />

            <div
              css={[
                css`
                  width: 24px;
                `,
                tw`border`
              ]}
            /> */}
          </div>
        </Grid>
      </header>
    );
  }

  return (
    <header
      css={[
        css`
          //
        `,
        tw`w-full fixed top-0 right-0 left-0 z-40 bg-white border-t border-b border-koamaru text-koamaru`
      ]}
    >
      <Grid node="nav" styles={[tw`relative items-center`]}>
        <div
          css={[
            css`
              // padding: 14px 14px 0 14px;
            `,
            tw`col-span-18 relative`
          ]}
        >
          <Go
            to="https://www.frankdarling.com"
            styles={[
              css`
                padding: 14px 0 14px 14px;
                display: block;
              `
            ]}
          >
            <Logo.Wordmark
              styles={[
                css`
                  height: 42px;
                `,
                tw`relative`
              ]}
            />
          </Go>
        </div>

        <div tw="col-span-6 h-full relative flex items-center border-koamaru border-l">
          <Go
            to="https://www.frankdarling.com/about"
            styles={[
              css`
                &:hover {
                  text-decoration: underline;
                }
              `,
              tw`w-1/3 h-full relative flex items-center justify-center`
            ]}
          >
            <T.Body styles={[tw`uppercase`]}>About</T.Body>
          </Go>

          <Go
            to="https://www.frankdarling.com/contact"
            styles={[
              css`
                &:hover {
                  text-decoration: underline;
                }
              `,
              tw`w-1/3 h-full relative flex items-center justify-center`
            ]}
          >
            <T.Body styles={[tw`uppercase`]}>Help</T.Body>
          </Go>

          <div css={[tw`w-1/3 relative flex items-center justify-center`]}>
            <Go to="https://www.frankdarling.com/wishlist">
              <Icon.Wishlist
                styles={[
                  css`
                    width: 26px;
                    margin: 0 12px;
                  `,
                  tw`relative block`
                ]}
              />
            </Go>

            <Go to="https://www.frankdarling.com">
              <Icon.Cart
                styles={[
                  css`
                    width: 26px;
                    margin: 0 12px;
                  `,
                  tw`relative block`
                ]}
              />
            </Go>
          </div>
        </div>
      </Grid>

      <div css={[tw`w-full relative px-4 border-t border-koamaru`]}>
        <Grid node="nav" styles={[tw`items-center`]}>
          <div tw="col-span-24 md:col-span-18 relative flex items-center">
            <Go
              to="https://www.frankdarling.com/try-at-home"
              styles={[
                css`
                  &:hover {
                    text-decoration: underline;
                  }
                `
              ]}
            >
              <T.Body
                styles={[tw`relative mr-8 pt-3 pr-2 pb-3 pl-2 uppercase`]}
              >
                Try at home
              </T.Body>
            </Go>

            <Go
              to="https://www.frankdarling.com/collections"
              styles={[
                css`
                  &:hover {
                    text-decoration: underline;
                  }
                `
              ]}
            >
              <T.Body
                styles={[tw`relative mr-8 pt-3 pr-2 pb-3 pl-2 uppercase`]}
              >
                Collections
              </T.Body>
            </Go>

            <Go
              to="https://www.frankdarling.com/rings"
              styles={[
                css`
                  &:hover {
                    text-decoration: underline;
                  }
                `
              ]}
            >
              <T.Body
                styles={[tw`relative mr-8 pt-3 pr-2 pb-3 pl-2 uppercase`]}
              >
                Shop Rings
              </T.Body>
            </Go>

            <button
              type="button"
              onClick={() => setResetSearch(true)}
              css={[
                css`
                  &:hover {
                    text-decoration: underline;
                  }
                `
              ]}
            >
              <T.Body
                styles={[tw`relative mr-8 pt-3 pr-2 pb-3 pl-2 uppercase`]}
              >
                Shop Diamonds
              </T.Body>
            </button>

            <Go
              to="https://www.frankdarling.com/how-it-works"
              styles={[
                css`
                  &:hover {
                    text-decoration: underline;
                  }
                `
              ]}
            >
              <T.Body
                styles={[tw`relative mr-8 pt-3 pr-2 pb-3 pl-2 uppercase`]}
              >
                How it works
              </T.Body>
            </Go>

            <Go
              to="https://www.frankdarling.com/etiquette"
              styles={[
                css`
                  &:hover {
                    text-decoration: underline;
                  }
                `
              ]}
            >
              <T.Body
                styles={[tw`relative mr-8 pt-3 pr-2 pb-3 pl-2 uppercase`]}
              >
                Etiquette
              </T.Body>
            </Go>
          </div>
        </Grid>
      </div>
    </header>
  );
};

export default Header;
